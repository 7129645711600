<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondInfoTab.vue
Description:this file gives the info about the active inactive and total ponds and data ,date filtering etc and chart info
-->
<template>
  <el-row class="pond-info-tab" slot="layout-pondlogs-scrollable-main">
    <el-row type="flex" :gutter="20">
      <el-col :span="getGraphPanelStatus ? 14 : 24">
        <el-card class="pond-table-container">
          <el-row class="pre-table-header" type="flex">
            <el-col :span="10" class="pre-table-header__left">
              <el-radio-group
                :value="selectedTab"
                size="small"
                @input="handleTabClick($event, 'TAB_CLICK')"
              >
                <el-radio-button label="ACTIVE" class="is-plain">
                  {{ $t("Comn_active") }}
                </el-radio-button>
                <el-radio-button label="INACTIVE" class="is-plain">
                  {{ $t("Comn_inactive") }}
                </el-radio-button>
                <el-radio-button label="ALL" class="is-plain">
                  {{ $t("Comn_all") }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col
              :span="14"
              class="pre-table-header__right"
              v-bind:class="{ rightWidthAdj: !isDeactive }"
            >
              <layout-toolbar gap="5">
                <div
                  class="day-picker-top"
                  v-bind:class="{ hideDayPicker: !isDeactive }"
                >
                  <er-single-day-picker
                    size="mini"
                    :clearable="false"
                    :value="date"
                    :picker-options="pickerOptions"
                    :disabled="true"
                    value-format="yyyy-MM-dd"
                    :format="this.upm__getFormatDateString"
                    @change="handleChangeInDateRange"
                  ></er-single-day-picker>
                </div>
                <span
                  class="material-icons-outlined action-icon"
                  @click="clearSelectionClick('Click')"
                  style="cursor: pointer"
                >
                  clear_all
                </span>
                <er-list-filter
                  :data="getFilterData"
                  value-key="pond_id"
                  search-on-key="label"
                  class="highlight"
                  :defaultCheckedKeys="getAvailablePondIds"
                  @check-change="handleChangeInSelectedPonds"
                >
                  <template v-slot:label="{ item }">{{ item.label }}</template>
                </er-list-filter>
                <er-column-selector
                  :key-to-store-defaults="`${getUserId}-TABLE-PREF-POND-INFO`"
                  :columns="getTableColumnsFromRegistry"
                  :keyToDecideDefaultCol="keyToDecideDefaultCol"
                  :srcImg="'COLUMN_ICON'"
                  :type="'pondInfo'"
                  :title="ftm__capitalize($t('columns'))"
                  @column-save="handelSaveColumns"
                  @change-selection="handleChangeInColumnSelection"
                ></er-column-selector>
                <div class="search-bar" :title="$t('Comn_search')">
                  <el-input
                    class="search-bar-size"
                    v-show="!isDeactive"
                    v-model="pondSearch"
                    size="small"
                    :placeholder="$t('Pond_search_pond')"
                    @change="handleInput"
                  ></el-input>
                  <span
                    @click="searchClick"
                    class="material-icons-outlined action-icon"
                  >
                    search
                  </span>
                </div>
                <er-dropdown
                  placement="bottom"
                  @item-click="handlePondInfoDownload"
                  trigger="click"
                  :listOfItems="[
                    'Comn_download_as_excel',
                    'Comn_download_as_pdf'
                  ]"
                >
                  <template
                    class="el-dropdown-link download_button"
                    slot="suffix"
                  >
                    <span class="material-icons-outlined action-icon">
                      file_download
                    </span>
                  </template>
                  <template v-slot:item-title="{ item }">{{
                    $t(item.value)
                  }}</template>
                </er-dropdown>
                <er-button
                  class="no-padding"
                  type="text"
                  v-if="getGraphPanelStatus"
                  :title="$t('expand')"
                  size="mini"
                  @click="handleExpandClick(false, 'Click')"
                >
                  <span class="action-icon-group">
                    <span class="material-icons-outlined">
                      keyboard_arrow_left
                    </span>
                    <span class="material-icons-outlined">
                      keyboard_arrow_right
                    </span>
                  </span>
                </er-button>
                <er-button
                  type="text"
                  class="no-padding"
                  v-else
                  :title="$t('show_graphs')"
                  size="mini"
                  @click="handleExpandClick(true, 'Click')"
                >
                  <span class="action-icon-group">
                    <span class="material-icons-outlined">
                      keyboard_arrow_right
                    </span>
                    <span class="material-icons-outlined">
                      keyboard_arrow_left
                    </span>
                  </span>
                </er-button>
              </layout-toolbar>
            </el-col>
          </el-row>
          <el-row v-loading="loading" element-loading-background="white">
            <Loader v-if="loading"></Loader>
            <card-all-ponds-table
              :filteredPonds="tableData"
              v-if="!loading"
              :columns="tableColumns"
              :selectedHeaders="selectedColumns"
              :pondSearch="pondSearch"
              :userCountryCode="getUserProfile.country.code"
            />
          </el-row>
        </el-card>
      </el-col>

      <el-col
        :span="getGraphPanelStatus ? 10 : 0"
        style="padding-left: 0px; padding-right: 0px"
        v-loading="loading"
        element-loading-background="white"
      >
        <el-row>
          <info-charts v-show="!loading"></info-charts>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin.js";
import infoCharts from "./components/infoCharts";
import filtersMixin from "@/mixins/filtersMixin.js";
import cardAllPondsTable from "./components/cardAllPondsTable";
import tableColumnsRegistry from "./utils/tableColumns";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import exportFileDownload from "@/mixins/exportFileDownload";
export default {
  name: "PondInfoTab",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    exportFileDownload
  ],
  data: function() {
    return {
      date: null,
      visible: false,
      isDeactive: true,
      pondSearch: "",
      filteredponds: [],
      selectedPondsIds: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      selectedHeaders: {},
      selectedColumns: [],
      columnsInRegistry: [],
      selectedColumnsKeys: [],
      loading: true,
      params: {
        get_all: true,
        status: ["ACTIVE", "INACTIVE"]
      },
      query: {
        location_id: null,
        biomass_units: "KG",
        include: ["title", "pond_mothers", "shrimp_talks", "pond_guards"]
      }
    };
  },
  components: {
    cardAllPondsTable,
    infoCharts,
    Loader
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZone: "getUserTimeZone",
      getUserCountryName: "getUserCountryName",
      getUserProfile: "getUserProfile",
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    ...mapGetters("pondInfo", {
      getSelectedTab: "getSelectedTab",
      getActivePonds: "getActivePonds",
      getInactivePonds: "getInactivePonds",
      getAllPonds: "getAllPonds",
      getAllHyperLinkPonds: "getAllHyperLinkPonds",
      getPondIdToLatestNDOTempValues: "getPondIdToLatestNDOTempValues",
      getGraphPanelStatus: "getGraphPanelStatus",
      getLatestNDOTempValues: "getLatestNDOTempValues",
      doesUserLocationHasPgsDetails: "doesUserLocationHasPgsDetails",
      getPondMode: "getPondMode",
      getPondIdToLatestNABWValues: "getPondIdToLatestNABWValues",
      getPgDeviceCount: "getPgDeviceCount"
    }),
    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },

    getUserId() {
      return this.getUserProfile._id;
    },
    pondsForSelection() {
      let ponds = [];
      if (this.selectedTab === "ACTIVE") {
        ponds = this.activePonds || [];
      } else if (this.selectedTab === "INACTIVE") {
        ponds = this.inactivePonds || [];
      } else if (this.selectedTab === "ALL") {
        ponds = this.getAllPonds || [];
      }
      return ponds;
    },
    getFilterData() {
      return this.pondsForSelection
        .map(x => {
          return { pond_id: x._id, label: x.title };
        })
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.label, b.label)
        );
    },
    getAvailablePondIds() {
      return this.pondsForSelection.map(x => x._id);
    },
    selectedTab() {
      return this.getSelectedTab;
    },
    activePonds: function() {
      return this.getActivePonds || [];
    },
    inactivePonds: function() {
      return this.getInactivePonds;
    },
    getTableColumnsFromRegistry() {
      // return this.columnsInRegistry.reduce((accCol, { key_name }) => {
      //   accCol[key_name] = this.tableColumns[key_name];
      //   return accCol;
      // }, {});
      return this.tableColumns
    },
    FeedingLevelTranslations() {
      return (feeding_level) => {
        const translations = {
          NORMAL: this.$t("PM_normal"),
          ECONOMICAL: this.$t("PM_economical"),
          AGGRESSIVE: this.$t("PM_Aggressive"),
          HYBRID: this.$t("PM_hybrid"),
          NONE: ""
          }
        return translations[feeding_level];
      }
    },
    downloadColumns() {
      const downloadFieldsArray = [
        {
          header: this.tableColumns.title.label,
          dataKey: "title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header:
            this.tableColumns.size.label +
            "(" +
            this.tableColumns.size.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.upm__applyConversion(
                value.size,
                value.area_units,
                this.getPreferredUnits.pond_area_units
              ), 2
            );
          },
          emptyValue: "--",
          name: "size",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.days_of_cultivation.label +
            "(" +
            this.tableColumns.days_of_cultivation.sub_label +
            ")",
          dataKey: "days_of_cultivation",
          emptyValue: "--",
          name: "days_of_cultivation",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.abw.label +
            "(" +
            this.tableColumns.abw.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.getPondIdToLatestNABWValues[value._id][3].abw,
              1,
              "round"
            ) >= 0.1
              ? this.ftm__digitPrecision(
                  this.getPondIdToLatestNABWValues[value._id][3].abw,
                  2
                )
              : "--";
          },
          emptyValue: "0",
          name: "abw",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.awg.label +
            "(" +
            this.tableColumns.awg.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.getPondIdToLatestNABWValues[value._id][3].awg,
              1,
              "round"
            ) >= 0.1
              ? this.ftm__digitPrecision(
                  this.getPondIdToLatestNABWValues[value._id][3].awg,
                  1
                )
              : "--";
          },
          emptyValue: "0",
          name: "awg",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.do.label +
            "(" +
            this.tableColumns.do.sub_label +
            ")",
          dataKey: value => {
            return this.getPondIdToLatestNDOTempValues[value._id]
              .map(item => this.ftm__digitPrecision(item.do, 1))
              .join(" ");
          },
          emptyValue: "--",
          name: "do",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.temp.label +
            "(" +
            this.tableColumns.temp.sub_label +
            ")",
          dataKey: value => {
            return this.getPondIdToLatestNDOTempValues[value._id]
              .map(item => this.ftm__digitPrecision(item.temp, 1))
              .join(" ");
          },
          emptyValue: "--",
          name: "temp",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.biomass.label +
            "(" +
            this.tableColumns.biomass.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.upm__applyConversion(
                value.biomass,
                value.biomass_units,
                this.getPreferredUnits.biomass_units
              ),
              1
            );
          },
          emptyValue: "--",
          name: "biomass",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.feed.label +
            "(" +
            this.tableColumns.feed.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.feed, 1);
          },
          emptyValue: "--",
          name: "feed",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.dispensed_feed.label +
            "(" +
            this.tableColumns.dispensed_feed.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.dispensed_feed, 1);
          },
          emptyValue: "--",
          name: "dispensed_feed",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.total_feed.label +
            "(" +
            this.tableColumns.total_feed.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.total_dispensed_feed, 1);
          },
          emptyValue: "--",
          name: "total_feed",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.total_dispensed_feed_for_manual.label +
            "(" +
            this.tableColumns.total_dispensed_feed_for_manual.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.total_dispensed_feed_for_manual, 1);
          },
          emptyValue: "--",
          name: "total_dispensed_feed_for_manual",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.total_dispensed_feed_in_auto_mode.label +
            "(" +
            this.tableColumns.total_dispensed_feed_in_auto_mode.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.total_dispensed_feed_in_auto_mode, 1);
          },
          emptyValue: "--",
          name: "total_dispensed_feed_in_auto_mode",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.total_dispensed_feed_in_schedule_mode.label +
            "(" +
            this.tableColumns.total_dispensed_feed_in_schedule_mode.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.total_dispensed_feed_in_schedule_mode, 1);
          },
          emptyValue: "--",
          name: "total_dispensed_feed_in_schedule_mode",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.total_dispensed_feed_in_basic_mode.label +
            "(" +
            this.tableColumns.total_dispensed_feed_in_basic_mode.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(value.total_dispensed_feed_in_basic_mode, 1);
          },
          emptyValue: "--",
          name: "total_dispensed_feed_in_basic_mode",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header:
            this.tableColumns.feed_per_area.label +
            this.tableColumns.feed_per_area.sub_label,
          dataKey: (value) => {
            const values = this.upm__applyConversion(
                [value.total_dispensed_feed, value.size],
                ["kg", value.area_units],
                [this.upm__feedUnits, this.upm__getPondSizeUnits]
              )
            return this.ftm__digitPrecision(
              values[0] / values[1],
              1
            );
          },
          emptyValue: "--",
          name: "feed_per_area",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.post_larva_stocked.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.post_larva_stocked, 0);
          },
          emptyValue: "--",
          name: "post_larva_stocked",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.survival_percentage.label,
          dataKey: value => {
            return `${this.ftm__digitPrecision(value.survival_percentage, 1)}%`;
          },
          emptyValue: "--",
          name: "survival_percentage",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },

        {
          header: this.tableColumns.fcr.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.fcr, 2);
          },
          emptyValue: "--",
          name: "fcr",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.mode.label,
          dataKey: value => {
            return this.mode(value);
          },
          emptyValue: "--",
          name: "mode",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.pond_mothers_count.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.pond_mothers_count, 1);
          },
          emptyValue: "--",
          name: "pond_mothers_count",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.pond_response_quality.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.pond_response_quality, 1);
          },
          emptyValue: "--",
          name: "pond_response_quality",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.biomass_per_pond_mother.label +
            "(" +
            this.tableColumns.biomass_per_pond_mother.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.upm__applyConversion(
                value.biomass_per_pond_mother,
                value.biomass_units,
                this.getPreferredUnits.biomass_units
              ),
              1
            );
          },
          emptyValue: "--",
          name: "biomass_per_pond_mother",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.biomass_per_ha.label +
            "(" +
            this.tableColumns.biomass_per_ha.sub_label +
            ")",
          dataKey: value => {
            return this.ftm__digitPrecision(
              this.upm__applyConversion(
                value.biomass_per_ha,
                value.biomass_units,
                this.getPreferredUnits.biomass_units
              ),
              1
            );
          },
          emptyValue: "--",
          name: "biomass_per_ha",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.shrimps_per_pond_mother.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.shrimps_per_pond_mother, 1);
          },
          emptyValue: "--",
          name: "shrimps_per_pond_mother",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.stocking_density.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.stocking_density, 1);
          },
          emptyValue: "--",
          name: "stocking_density",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.shrimp_talk_working_hours.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.shrimp_talk_working_hours, 1);
          },
          emptyValue: "--",
          name: "shrimp_talk_working_hours",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.shrimp_talks_count.label,
          dataKey: value => {
            return this.ftm__digitPrecision(value.shrimp_talks_count, 1);
          },
          emptyValue: "--",
          name: "shrimp_talks_count",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
      ];
      if (this.selectedTab !== "INACTIVE") {
        const removed = downloadFieldsArray.splice(19, 0, {
            header: this.tableColumns.feeder_type.label,
            dataKey: "feeder_type",
            emptyValue: "--",
            name: "feeder_type",
            skipColumn: false,
            formatters: [],
            xcelcolumnWidth: 25
          },
          {
            header: this.tableColumns.feeding_level.label,
            dataKey: value => {
              return this.FeedingLevelTranslations(value.feeding_level)
            },
            emptyValue: "--",
            name: "feeding_level",
            skipColumn: false,
            formatters: [],
            xcelcolumnWidth: 25
          })
        console.log("downloadFieldsArray", removed);
      }
      return downloadFieldsArray;
    },
    columnWidth() {
      return this.getPgDeviceCount === 3 ? 120 : this.getPgDeviceCount >= 4 ? 160 : 85;
    },
    tableColumns() {
      return {
        title: {
          label: this.$t("Comn_name"),
          prop: "title",
          sortable: true,
          minWidth: 90,
          fixed: "left",
          align: "left",
          default: true,
          enableSearch: true,
          searchProperty: "title",
          sortMethod: (a, b) =>
            this.$commonUtils.alphaNumericComparator(a.title, b.title),
          defaultFullTable: true,
          ispdf: true
        },
        size: {
          label: this.$tc("Pond_size", 1),
          sub_label: this.$t(this.getPreferredUnits.pond_area_units),
          prop: "size",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left",
          ispdf: true
        },
        days_of_cultivation: {
          label: this.$t("Comn_doc"),
          sub_label: this.$t("Comn_days"),
          prop: "days_of_cultivation",
          sortable: true,
          default: true,
          defaultFullTable: true,
          minWidth: 90,
          align: "left",
          ispdf: true
        },
        abw: {
          label: this.$t("ABW_abw"),
          sub_label: "g",
          prop: "abw",
          sortable: true,
          align: "left",
          default: true,
          defaultFullTable: true,
          ispdf: true
        },
        awg: {
          label: this.$t("Comn_awg"),
          sub_label: "g",
          prop: "awg",
          sortable: true,
          align: "left",
          default: false,
          defaultFullTable: true,
          ispdf: true
        },
        do: {
          label: this.$t("Comn_do"),
          sub_label: "mg/L",
          prop: "do",
          sortable: true,
          minWidth: this.columnWidth,
          default: false,
          defaultFullTable: false,
          align: "left",
          sortMethod: (a, b) => this.sortDo(a, b),
          ispdf: true
        },
        temp: {
          label: this.$t("Comn_temp"),
          sub_label: "\u00b0C",
          prop: "temp",
          sortable: true,
          minWidth: this.columnWidth,
          default: false,
          defaultFullTable: false,
          align: "left",
          sortMethod: (a, b) => this.sortTemperature(a, b),
          ispdf: true
        },
        biomass: {
          label: this.$t("Comn_biomass"),
          sub_label: this.$tc(this.getPreferredUnits.biomass_units, 0),
          prop: "biomass",
          sortable: true,
          align: "left",
          default: true,
          minWidth: 110,
          defaultFullTable: true,
          ispdf: true
        },
        feed: {
          label: this.$t("total_feed"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "feed",
          sortable: true,
          minWidth: 120,
          default: true,
          defaultFullTable: true,
          align: "left",
          ispdf: true
        },
        dispensed_feed: {
          label: this.$t("dispensed_Feed"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "dispensed_feed",
          sortable: true,
          minWidth: 150,
          default: true,
          defaultFullTable: true,
          align: "left",
          ispdf: true
        },
        total_feed: {
          label: this.$t("Comn_net_feed"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "total_feed",
          sortable: true,
          minWidth: 150,
          default: true,
          defaultFullTable: true,
          align: "left",
          ispdf: true
        },
        total_dispensed_feed_for_manual: {
          label: this.$t("Comn_manual"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "total_dispensed_feed_for_manual",
          sortable: true,
          minWidth: 100,
          default: true,
          defaultFullTable: false,
          align: "left",
          ispdf: true
        },
        total_dispensed_feed_in_auto_mode: {
          label: this.$t("PM_automatic"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "total_dispensed_feed_in_auto_mode",
          sortable: true,
          minWidth: 100,
          default: true,
          defaultFullTable: false,
          align: "left",
          ispdf: true
        },
        total_dispensed_feed_in_schedule_mode: {
          label: this.$t("Comn_schedule"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "total_dispensed_feed_in_schedule_mode",
          sortable: true,
          minWidth: 100,
          default: true,
          defaultFullTable: false,
          align: "left",
          ispdf: true
        },
        total_dispensed_feed_in_basic_mode: {
          label: this.$t("PM_sch_mode4"),
          sub_label: this.$tc(this.upm__feedUnits, 0),
          prop: "total_dispensed_feed_in_basic_mode",
          sortable: true,
          minWidth: 100,
          default: true,
          defaultFullTable: false,
          align: "left",
          ispdf: true
        },
        feed_per_area: {
          label: this.$t("feed-area"),
          sub_label: '',
          prop: "feed_per_area",
          sortable: true,
          minWidth: 100,
          default: false,
          defaultFullTable: true,
          sortMethod: (a, b) => this.sortFeedArea(a, b),
          align: "left",
          ispdf: true
        },
        post_larva_stocked: {
          label: this.$t("Pond_pls_stocked"),
          prop: "post_larva_stocked",
          sortable: true,
          minWidth: 108,
          defaultFullTable: true,
          default: false,
          align: "left",
          ispdf: true
        },
        survival_percentage: {
          label: this.$t("Comn_survival_percentage"),
          prop: "survival_percentage",
          sortable: true,
          minWidth: 115,
          defaultFullTable: true,
          align: "left",
          default: false,
          ispdf: true
        },
        fcr: {
          label: this.$t("Comn_fcr"),
          prop: "fcr",
          sortable: true,
          minWidth: 80,
          defaultFullTable: true,
          default: false,
          align: "left",
          ispdf: true
        },
        mode: {
          label: this.$t("mode"),
          prop: "mode",
          sortable: true,
          width: 100,
          defaultFullTable: true,
          default: false,
          align: "left",
          ispdf: true,
          enableSearch: true,
          searchProperty: "mode"
        },
        pond_mothers_count: {
          label: this.$t("Comn_pm_count"),
          prop: "pond_mothers_count",
          sortable: true,
          minWidth: 80,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        feeder_type: {
          label: this.$t("Comn_feeder_type"),
          prop: "feeder_type",
          sortable: true,
          minWidth: 100,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        feeding_level: {
          label: this.$t("Comn_feeding_level"),
          prop: "feeding_level",
          sortable: true,
          minWidth: 100,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        pond_response_quality: {
          label: this.$t("Comn_pond_response_quality"),
          prop: "pond_response_quality",
          sortable: true,
          minWidth: 100,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        biomass_per_pond_mother: {
          label: this.$t("Comn_biomass_per_pond_mother"),
          sub_label: this.$tc(this.getPreferredUnits.biomass_units, 0),
          prop: "biomass_per_pond_mother",
          sortable: true,
          minWidth: 120,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        biomass_per_ha: {
          label: this.$t("Comn_biomass_per_ha"),
          sub_label: this.$tc(this.getPreferredUnits.biomass_units, 0),
          prop: "biomass_per_ha",
          sortable: true,
          minWidth: 120,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        shrimps_per_pond_mother: {
          label: this.$t("Comn_shrimps_per_pond_mother"),
          prop: "shrimps_per_pond_mother",
          sortable: true,
          minWidth: 100,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        stocking_density: {
          label: this.$t("Comn_stocking_density"),
          prop: "stocking_density",
          sortable: true,
          minWidth: 100,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        shrimp_talk_working_hours: {
          label: this.$t("Comn_shrimp_talk_working_hours"),
          prop: "shrimp_talk_working_hours",
          sortable: true,
          minWidth: 150,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
        shrimp_talks_count: {
          label: this.$t("Comn_st_count"),
          prop: "shrimp_talks_count",
          sortable: true,
          minWidth: 80,
          defaultFullTable: false,
          default: false,
          align: "left",
          ispdf: true
        },
      };
    },
    pondSizeUnits: function() {
      return this.getPreferredUnits.pond_area_units === 'ac' // this.getUserCountryName.toLowerCase() === "india"
        ? { units: "ac", lang_key: "Pond_size_units_ac" }
        : { units: "ha", lang_key: "Pond_size_units_ha" };
    },
    tableData: function() {
      let ponds = [];
      if (this.selectedTab === "ACTIVE") {
        ponds = this.activePonds || [];
      } else if (this.selectedTab === "INACTIVE") {
        ponds = this.inactivePonds || [];
      } else if (this.selectedTab === "ALL") {
        ponds = this.getAllPonds || [];
      }
      const finalPondsObjById = ponds.reduce((acc, pond) => {
        acc[pond._id] = pond;
        return acc;
      }, {});
      let finalPondsArr = ponds;
      if (ponds.length === 0) return [];
      // dashboard

      if ((this.getAllHyperLinkPonds || []).length > 0) {
        const pondIds = this.getAllHyperLinkPonds.map(x => x._id);
        finalPondsArr = pondIds.map(id => finalPondsObjById[id]);
      } else if ((this.selectedPondsIds || []).length > 0) {
        // pond filter
        const pondIds = this.selectedPondsIds;
        finalPondsArr = pondIds.map(id => finalPondsObjById[id]);
      }
      // searching
      finalPondsArr = finalPondsArr.filter(
        pond =>
          !this.pondSearch ||
          (pond || { title: "No Data" }).title
            .toLowerCase()
            .includes(this.pondSearch.toLowerCase())
      );

      if (finalPondsArr.length > 0) {
        return finalPondsArr;
      } else {
        return ponds;
      }
    },
    keyToDecideDefaultCol: function() {
      return !this.getGraphPanelStatus ? "defaultFullTable" : "default";
    }
  },
  async mounted() {
    this.initDate();

    this.handleExpandClick(false);
    await this.initComponent();
    // this.columnsInRegistry = tableColumnsRegistry.getOrderOfColumnKeys();
    // console.log(this.columnsInRegistry);
    // tableColumnsRegistry.addListeners({
    //   event: "onColumnsChange",
    //   callBack: (columns) => {
    //     this.columnsInRegistry = tableColumnsRegistry.getOrderOfColumnKeys();
    //   },
    // });
  },
  async beforeDestroy() {
    await this.clearSelectionClick();
  },
  destroyed() {
    tableColumnsRegistry.unRegister({ colGroupName: "pond-gaurd-params" });
  },
  methods: {
    ...mapActions("pondInfo", {
      changeSelectedTab: "changeSelectedTab",
      changeSelectedPonds: "changeSelectedPonds",
      changeLocation: "changeLocation",
      changeDashboardTabSelectedPondIds: "changeDashboardTabSelectedPondIds",
      changeShowGraphsPanelStatus: "changeShowGraphsPanelStatus"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    mode: function(data) {
      const value = this.getPondMode[data._id];
      if (value === "SHRIMP_TALK") {
        return this.$t("PM_automatic");
      } else if (value === "HYBRID") {
        return this.$t("hybrid");
      } else if (value === "FARMER") {
        return this.$t("Comn_schedule");
      } else if (value === "MANUAL") {
        return this.$t("PM_sch_mode4");
      } else if (value === "NO_PMS") {
        return "--";
      }
    },
    getsortFeedArea(feed, size) {
      return feed / size;
    },
    updatePondSizeBasedOnUnits(size, units) {
      // const userLocation = this.getUserCountryName.toLowerCase();
      // if (userLocation === "india") {
      //   return Number(
      //     this.$commonUtils.castPondAreaSize(size, units, "ac")
      //   ).toFixed(2);
      // }
      return Number(
        this.$commonUtils.castPondAreaSize(size, units, this.getPreferredUnits.pond_area_units)
      ).toFixed(2);
    },
    handlePondInfoDownload(downloadtype) {
      this.mixPanelEventGenerator({ eventName: "Ponds Info - Download" });
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
    },
    downloadData() {
      const downloadData = this.tableData.map(eachItem => {
        eachItem.survival_percentage =
          eachItem.status === "INACTIVE"
            ? 0
            : eachItem.survival_percentage || 100;
        return eachItem;
      });
      console.log(downloadData);
      return downloadData;
    },

    xceldownload() {
      // x.status === "INACTIVE" ? 0 : x.survival_percentage || 100;
      this.exp__Download(
        this.downloadData().sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        ),
        this.downloadColumns,
        "xcel"
      );
    },

    pdfdownload() {
      this.exp__Download(
        this.downloadData().sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        ),
        this.downloadColumns,
        "20column_pdf"
      );
    },

    digitPrecision(input) {
      return input.toFixed(2);
    },
    async handleTabClick(selectedLabel, eventTrigger) {
      this.loading = true;
      if (eventTrigger === "TAB_CLICK") {
        await this.changeDashboardTabSelectedPondIds({
          pondIds: [],
          tab: selectedLabel
        });
        this.mixPanelEventGenerator({ eventName: "Ponds Info - Table Data - " + selectedLabel });
      }
      await this.changeSelectedTab(selectedLabel);
      this.loading = false;
    },
    sortDo(a, b) {
      return (
        (this.getPondIdToLatestNDOTempValues[a._id].map(x => x.do)[0] || -999) -
        (this.getPondIdToLatestNDOTempValues[b._id].map(x => x.do)[0] || -999)
      );
    },
    sortTemperature(a, b) {
      return (
        (this.getPondIdToLatestNDOTempValues[a._id].map(x => x.temp)[0] ||
          -999) -
        (this.getPondIdToLatestNDOTempValues[b._id].map(x => x.temp)[0] || -999)
      );
    },
   sortFeedArea(a, b) {
     console.log('a,b', a, b);
      return (
        (this.getsortFeedArea(a.total_dispensed_feed, this.upm__applyConversion(a.size, a.area_units, this.getPreferredUnits.pond_area_units )
          ) -
        (this.getsortFeedArea(b.total_dispensed_feed, this.upm__applyConversion(b.size, b.area_units, this.getPreferredUnits.pond_area_units )
          ))))
    },
    async clearSelectionClick(data) {
      await this.changeDashboardTabSelectedPondIds({
        pondIds: [],
        tab: "ACTIVE"
      });
      if (data === 'Click') {
        this.mixPanelEventGenerator({ eventName: "Ponds Info - Clear All Icon" });
      }
      await this.changeSelectedPonds({ selected_ponds: [] });
    },
    handleChangeInColumnSelection(selectedColumns) {
      // const columnObj = selectedColumns.reduce((acc, curr) => {
      //   acc[curr] = this.tableColumns[curr];
      //   return acc;
      // }, {});
      this.selectedColumnsKeys = selectedColumns;
      // this.selectedHeaders = Object.assign({}, columnObj);
      this.selectedColumns = selectedColumns;
    },
    handelSaveColumns() {
      this.mixPanelEventGenerator({ eventName: "Ponds Info - Column Selector - Save" });
    },
    handleChangeInSelectedPonds(selectedPondsIds) {
      this.selectedPondsIds = selectedPondsIds;
      this.mixPanelEventGenerator({ eventName: "Ponds Info - Filter" });
    },
    async handleChangeInDateRange(event) {
      try {
        await this.initComponent();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async prevDayClick() {},
    async nextDayClick() {},
    searchClick(event) {
      this.isDeactive = !this.isDeactive;
    },
    initDate() {
      this.date = this.dhm__dateUtilsLib.formatTZWithLocale(
        this.dhm__dateUtilsLib.startOfDay(this.dhm__getTodayInUserTZ),
        "yyyy-MM-dd"
      );
    },
    async initComponent() {
      this.loading = true;
      try {
        const selectedTab = (
          this.$route.query.pondStatus || this.getSelectedTab
        ).toUpperCase();
        await this.changeLocation(this.getCurrUserLocation);
        await this.handleTabClick(selectedTab, "INIT");
        if (this.doesUserLocationHasPgsDetails) {
          tableColumnsRegistry.register({
            colGroupName: "pond-gaurd-params",
            columns: {
              do: { key_name: "do", order: 6 },
              temperature: { key_name: "temp", order: 7 }
            }
          });
        }
        // this.handleChangeInColumnSelection(this.selectedColumnsKeys);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    pondNameFilterHandler(value, row, column) {
      const property = column.property;
      return row[property] === value;
    },
    async handleExpandClick(expandStatus, data) {
      if (data === 'Click') {
        this.mixPanelEventGenerator({ eventName: "ponds Info - Show Graphs" });
      }
      this.changeShowGraphsPanelStatus(expandStatus);
      // await this.fetchAllPondsPmDetails();
    },
    handleInput() {
      if (this.pondSearch !== '') {
        this.mixPanelEventGenerator({ eventName: "ponds Info - Search" });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins.scss";
.pond-info-tab {
  .icon {
    opacity: 0.75;
  }
  .hightlight {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .el-card__body {
    padding: 10px;
  }
  .pre-table-header {
    &__right {
      position: relative;
      float: right;
      display: flex;
      justify-content: flex-end;
      .day-picker-top {
        display: flex;
        // @include responsiveProperty(width, 100px, 150px, 150px);
      }
      .action-icon {
        cursor: pointer;
        color: black;
        opacity: 0.3;
        font-weight: 500;
        @include icon-size(icon-font);
        &:hover {
          opacity: 1;
        }
      }
      .er-list-filter .material-icons-outlined {
        @include icon-size(icon-font);
      }
      .btn-icon-svg {
        @include icon-size(svg);
      }
      .er-button.el-button--text {
        padding: 0px;
      }
      .action-icon-group {
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }

        .material-icons-outlined {
          cursor: pointer;
          color: black;
          font-weight: 500;
          @include icon-size(icon-font);
          & + .material-icons-outlined {
            margin-left: -10px;
          }
        }
      }
      .el-select {
        margin-left: 0.5rem;
        .el-input__inner {
          border: 0px solid #dcdfe6;
        }
      }
      .search-bar {
        position: relative;
        display: flex;
        align-items: center;
        // padding-right: 4px;
        input {
          background: white;
          border: 0px solid #dcdfe6;
          border-radius: 8px;
          color: black !important;
        }
        img {
          cursor: pointer;
          width: 16px;
          position: absolute;
          bottom: 0;
          right: 0;
          margin: 6px;
        }
        .el-input--small .el-input__inner {
          height: 28px;
          line-height: 28px;
        }
        .search_icon {
          @include normal-text;
        }
      }
      .search-bar-size {
        width: 180px;
        border-bottom: 0.7px solid gray;
      }
      .hideDayPicker {
        display: none !important;
      }
      .save-pref-container {
        display: flex;
        padding-left: 12px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-top: 1px solid lightgray;
        p {
          padding-left: 10px;
          color: #44a1ff;
          cursor: pointer;
        }
        img {
          cursor: pointer;
          width: 16px;
        }
      }
    }
    &__left {
      .el-radio-group {
        .el-radio-button__inner {
          @include small-text;
          padding: 7px 15px;
        }
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #ffffff;
        background: $button-color;
        border-color: $button-color;
        box-shadow: -1px 0 0 0 $button-color;
      }
      .el-radio-button__orig-radio + .el-radio-button__inner {
        color: #0a2463fe;
      }
      .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
        opacity: 0.6;
      }
    }
  }

  .el-card {
    border-radius: 7px;
  }
}
.download_dropdown {
  color: #606266;
  cursor: pointer;
  .el-dropdown-menu__item {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}
</style>
